@import "styles/mixins";

$radius: 8px;

.container{
  display: flex;
  flex-direction: column;
}

.bookingVisitInfo{
  background: var(--visit-info-bg, var(--newCardBook_booked_background));

  padding: 7px;

  border-radius: $radius;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  color: var(--alto);

  --icon-color: currentColor;
}

.withOverbooking{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.info{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  white-space: nowrap;
  color: var(--gl_text_inverse);
  @include tablet{
    flex-wrap: wrap;
  }
}

.time{
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--timer-color, var(--newCardBook_booked_timer));
  font: var(--font-15-b);
}

.blockWithIcon{
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--newCardBook_other_timer);
  font: var(--font-15-b);

  svg {
    flex: none;
  }
}

.overbookingHint{
  display: flex;
  align-items: center;
  gap: 8px;

  background: var(--gl_background_attention);
  color: var(--gl_text_constant_primary2);
  font: var(--font-12-r);
  padding: 5px 8px;

  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

.commentShortContainer{
  min-width: 0;
}

.commentShort{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.comment{
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border-top: 0.5px solid rgba(136, 136, 136, .2);

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  height: 22px;
}

.transparent{
  color: var(--gl_text_inverse);
  font: var(--font-12-r);
}


