@import "../settings/settings.scss";

.card {
  --book-color: var(--dodger-blue);
  --book-bgc: var(--athens-gray);
  --card-border: var(--border-default);

  --ruble-sign: none;
  --ruble-bgc: var(--dodger-blue);

  --card-pt: 14px;
  --card-pr: 25px;
  --card-pb: 14px;
  --card-pl: 16px;

  --card-padding: var(--card-pt) var(--card-pr) var(--card-pb) var(--card-pl);

  --card-shadow: var(--shadow-thick);
  --circle-color: var(--dodger-blue);
  --ico-color: var(--dodger-blue);
  --ico-width: 24px;
  --info-font: var(--font-14-r);
  --info-spacing: 5px;
  --info-padding: 3px 0 0 0;
  --tags-margin-top: 13px;
  --tags-flex-basis: auto;
  --ref-position: center;
  --ref-margin: 0;

  &.expired {
    --card-border: var(--border-2-solid-orchid);
    --card-padding: calc(var(--card-pt) - 1px) calc(var(--card-pr) - 1px) calc(var(--card-pb) - 1px)
      calc(var(--card-pl) - 1px);
    --card-shadow: var(--shadow-strong);
    --circle-color: var(--petite-orchid);
  }

  &.notCome {
    --card-border: var(--border-2-solid-hokey-pokey);
    --card-padding: calc(var(--card-pt) - 1px) calc(var(--card-pr) - 1px) calc(var(--card-pb) - 1px)
      calc(var(--card-pl) - 1px);
    // --card-shadow: var(--shadow-strong);
    --circle-color: var(--hokey-pokey);
  }

  &.confirmed {
    --card-border: 2px solid #8db972;
  }

  &.active {
    --card-border: var(--border-2-solid-blue);
    --card-padding: calc(var(--card-pt) - 1px) calc(var(--card-pr) - 1px) calc(var(--card-pb) - 1px)
      calc(var(--card-pl) - 1px);
  }
  &.upcoming {
    --card-shadow: var(--shadow-accent);
  }

  &.not-booked {
    --book-color: var(--athens-gray);
    --book-bgc: none;
    &-paid {
      --ruble-sign: "₽";
    }
    &-not-paid {
      --ruble-sign: "₽";
      --ruble-bgc: #cfd5e0;
    }
  }

  &.booked {
    &-paid {
      --ruble-sign: "₽";
    }
    &-not-paid {
      --ruble-sign: "₽";
      --ruble-bgc: #cfd5e0;
    }
  }

  &.inHall {
    --card-border: var(--border-2-solid-chateau-green);
  }

  @media (min-width: 950px) {
    --info-spacing: 22px;
  }

  @media (max-width: 1366px) {
    --card-pt: 10px;
    --card-pr: 9px;
    --card-pb: 14px;
    --card-pl: 9px;
    --ico-width: 20px;
    --info-font: var(--font-12-r);
    --info-padding: 0 0 0 2px;
    --ref-margin: -3px 0 0 0;
    --ref-position: center;
    --tags-margin-top: 10px;
    --tags-flex-basis: 100%;
    --info-spacing: 6px;
  }

  display: grid;
  grid-template-areas:
    "pic name ref"
    "pic info ref";
  grid-template-columns: min-content auto min-content;
  padding: var(--card-padding);
  box-shadow: var(--card-shadow);
  border: var(--card-border);
  border-radius: 3px;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &.no-pic {
    grid-template-areas:
      "name ref"
      "info ref";
    grid-template-columns: auto min-content;
  }

  .progress {
    grid-area: pic;
    width: 48px;
    height: 48px;
    align-self: start;
    margin-right: 12px;
  }

  .book {
    grid-area: pic;
    align-self: start;
    width: 44px;
    height: 44px;
    position: relative;
    margin-right: 12px;

    border-radius: 100%;
    border: var(--border-2-solid-athens);
    background: var(--book-bgc);
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1366px) {
      margin-right: 3px;
    }

    &.cancel > img {
      margin-left: -1px;
    }

    &:after {
      content: var(--ruble-sign);
      font: var(--font-14-r);
      top: -4px;
      box-sizing: border-box;
      right: -4px;
      padding-bottom: 1px;
      padding-left: 1px;
      position: absolute;
      color: white;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background: var(--ruble-bgc);
    }
  }

  .name {
    grid-area: name;
    font-size: 18px;
    font-weight: bold;
    color: var(--mine-shaft);
    white-space: nowrap;
    display: flex;
    align-items: center;
    .loyalty-bar {
      margin-left: 4px;
      margin-right: 11px;
    }

    @media (max-width: 1366px) {
      font-size: 16px;
    }
  }

  .info {
    padding: var(--info-padding);
    grid-area: info;
    display: flex;
    font: var(--info-font);
    color: var(--mine-shaft);
    flex-direction: column;
    flex-wrap: wrap;

    .numbers {
      display: flex;
      align-items: flex-end;
      margin-top: var(--tags-margin-top);
      .date,
      .time,
      .people,
      .spots,
      .hall {
        display: flex;
        align-items: center;
        margin-right: var(--info-spacing);
        height: 20px;
        img,
        svg {
          width: var(--ico-width);
          height: var(--ico-width);
          min-width: var(--ico-width);
          min-height: var(--ico-width);
          margin-right: 2px;

          @media (min-width: 950px) {
            margin-right: 7px;
          }
        }
      }

      .spots .cont {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .hall {
        span {
          min-width: max-content;
        }
      }
    }

    .source-comment {
      display: flex;
      align-items: center;

      .source {
        margin-right: var(--info-spacing);
      }
    }

    .comment,
    .source {
      margin-top: 3px;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }

    .comment {
      overflow: hidden;
      word-break: break-all; // No standart
      word-break: break-word;
    }

    .source img {
      min-width: 24px;
      max-height: 20px;
    }
  }

  .ref {
    grid-area: ref;
    align-self: var(--ref-position);
    margin: var(--ref-margin);
    height: 24px;
    width: 24px;
    justify-self: flex-end;
    background: none;
    user-select: none;
  }
}
