.icon {
  transform: rotate(-90deg);
  --color: #fff;
}

.visitControls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.recovery {
  --btn-secondary: var(--dusty-gray);
}


.print {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--gl_text_constant_secondary_disabled2);
  font-size: 14px;
  line-height: 16px;
}
