section.guest-card .detail .history-block {
  height: 60%;
  padding: 20px 20px 0px 20px;
  overflow: hidden;
  @media (max-width: 1366px) {
    padding: 20px 10px 0px 10px;
  }

  .title-history {
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
      flex-direction: column;
    }

    h2 {
      color: var(--mine-shaft);
      font: var(--titled-block-content-font);
    }

    .filters {
      display: flex;
      width: 250px;
      justify-content: flex-end;

      --border-multi: var(--border-1-solid-botticelli);
      .select {
        border: var(--border-1-solid-botticelli);
      }
    }

    .status {
      width: 225px;
    }

    .wrapper-select {
      width: 265px;
    }

    .rests {
      margin: 0px 10px;
      width: 245px;

      .css-bg1rzq-control,
      .css-1hwfws3 {
        height: calc(var(--height-a) - 2px);
      }
    }
  }

  .history-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    font: var(--msg-font);
    color: var(--cadet-blue);
    margin-top: 50px;
    text-align: center;
  }

  .content-history {
    background-clip: content-box;
    padding-right: 6px;
    height: calc(100% - 60px);
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .history {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #dde3ef;
      box-sizing: border-box;
      box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
      border-radius: 3px;
      padding: 15px;
      margin-bottom: 15px;
      height: 52px;

      @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
        flex-wrap: wrap;
        height: auto;
      }

      &:last-child {
        margin: 0;
      }

      .details .status {
        display: none;
      }

      &.admin-mode {
        height: initial;

        @media (min-width: 950px) {
          height: 96px;
        }

        @media (max-width: 1700px) {
          .status {
            display: none;
          }

          .details {
            display: grid;
            row-gap: 20px;
            grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));

            @media (min-width: 950px) {
              grid-template-columns: repeat(5, 1fr);
              grid-template-areas:
                "RST RST ... ... STS"
                "DTE CLC PRS TBL DUR";
            }

            div.date {
              @media (min-width: 950px) {
                grid-area: DTE;
              }
            }

            div.rest {
              @media (min-width: 950px) {
                grid-area: RST;
              }
              span {
                width: max-content;
              }
            }

            div.clock {
              justify-content: center;

              @media (min-width: 950px) {
                grid-area: CLC;
              }
            }

            div.persons {
              justify-content: center;
              @media (min-width: 950px) {
                grid-area: PRS;
              }
            }

            div.duration {
              justify-content: flex-start;
              @media (min-width: 950px) {
                grid-area: DUR;
              }

              span {
                margin: 0px;
              }
            }

            div.tables {
              justify-content: flex-start;
              @media (min-width: 950px) {
                grid-area: TBL;
              }

              span {
                @media (max-width: 1600px) {
                  min-width: 81px;
                }

                @media (max-width: 1366px) {
                  min-width: 38px;
                  max-width: 38px;
                }
              }
            }

            .status {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              @media (min-width: 950px) {
                grid-area: STS;
              }

              span {
                margin: 0px;
              }
            }
          }
        }
      }

      @media (max-width: 1366px) {
        padding: 5px;
      }

      img,
      svg {
        max-width: 22px;
        max-height: 22px;
        margin-right: 7px;
        @media (max-width: 1366px) {
          margin-right: 3px;
        }
      }

      span {
        color: #343434;

        @media (max-width: 1366px) {
          font-size: 14px;
        }
        @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
          font-size: 12px;
        }
      }

      .details {
        display: flex;
        align-items: center;
        @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
          flex-wrap: wrap;
          width: 60%;
        }

        .rest,
        .date,
        .clock,
        .duration,
        .tables,
        .persons {
          display: flex;
          align-items: center;
        }

        .persons span {
          min-width: 27px;
          @media (max-width: 1366px) {
            min-width: 5px;
          }
        }

        .rest span {
          width: min-content;

          @media (min-width: 1920px) {
            width: max-content;
          }
        }

        .duration span {
          min-width: 40px;
        }

        .tables span {
          min-width: 27px;
          display: inline-block;
          max-width: 81px;
          width: max-content;
          max-height: 32px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @media (max-width: 1366px) {
            min-width: 10px;
          }
        }

        span {
          margin-right: 20px;

          @media (max-width: 1366px) {
            margin-right: 5px;
            font-size: 14px;
          }
          @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
            font-size: 12px;
          }
        }

        div.tooltip {
          background: #3a3a3a;
          padding: 5px;
          border-radius: 3px;
          font-size: 14px;
          line-height: 16px;
          display: none;
          align-items: center;
          text-align: center;
          color: #ffffff;
          position: absolute;
          // top:0;

          &.active {
            display: flex;
          }
        }
      }

      .status {
        display: flex;
        align-items: center;

        span {
          min-width: 82px;
        }
      }
    }
  }

  &.admin-mode {
    .filters {
      width: 490px;
    }
    @media (max-width: 1070px) {
      padding: 0px;
    }

    .title-history {
      @media (max-width: 1070px) {
        padding: 20px;
      }

      @media (max-width: 1530px) {
        min-height: 31px;
        flex-direction: column;
        align-items: flex-start;
        width: auto;

        .filters {
          margin-top: 15px;
          justify-content: space-between;
          flex-direction: column;

          @media (min-width: 950px) {
            flex-direction: row;
            justify-content: flex-start;
          }

          > div {
            margin-bottom: 10px;

            @media (min-width: 950px) {
              margin-bottom: 0;
            }
          }

          .status {
            width: 195px;
          }

          .wrapper-select {
            width: 180px;

            .rests {
              margin: 0px 10px 0px 0px;
              width: 170px;

              .css-bg1rzq-control,
              .css-1hwfws3 {
                height: calc(var(--height-a) - 2px);
              }
            }
          }
        }
      }
    }

    .content-history {
      @media (max-width: 1070px) {
        padding: 0px 10px 0px 20px;
        width: calc(100% - 30px);
      }

      .details span {
        @media (max-width: 1366px) {
          margin-right: 10px;
        }
      }
    }
  }
}
