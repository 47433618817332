.info {
  width: 360px;
  height: 400px;
  box-sizing: border-box;

  box-shadow: var(--shadow-sign);
  border-radius: 3px;
  cursor: default;
}

.detail{
  display: grid;
  grid-template-columns: 1fr 30px;
  cursor: pointer;

  .button{
    width: 24px;
    height: 24px;
    align-self: center;
    background-color: var(--gl_icon_constant_primary);
    border-radius: 50%;
  }

  .icon{
    color: var(--gl_text_constant_primary2);
    transform: rotate(-90deg);
  }
}

.countRow {
  padding: 12px;
  border-top: 1px solid var(--gl_dividerBorder_primary_2);
  border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:not(:last-child) {
      border-right: 1px solid var(--gl_dividerBorder_primary_2);
    }

    .title {
      font: var(--font-12-r);
      font-weight: 400;
      color: var(--gl_text_secondary_disabled);;
    }

    .description {
      color: var(--gl_text_inverse);
      font: var(--font-14-b);
    }
  }
}

.shortInfo{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
  grid-row-gap: 10px;
  overflow-y: scroll;

  .item{
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      font: var(--font-12-r);
      font-weight: 400;
      color: var(--gl_text_secondary_disabled);;
    }

    .description {
      color: var(--gl_text_inverse);
      font: var(--font-14-b);
    }
  }

  .fullRow{
    grid-column: span 2;
  }
}
