@import 'styles/mixins';

.root{
  width: 100%;
}

.button{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--gl_icon_constant_secondary);
}

.light{
  @extend .button;
  display: block;
}

.dark {
  @extend .button;
  display: none;
}

@include forDarkTheme {
  .light {
    display: none;
  }
}

@include forDarkTheme {
.dark{
    display: block;
  }
}
