.bookingsCard{
  background: var(--gl_background_2);
  position: relative;
}


.bookingsCount {
  color: var(--gl_text_secondary_disabled);
}

.bookingsList{
  padding: 0;
  gap: 1px;
}

.list{
  min-height: 0;
  flex: 1 0;
  overflow: auto;
}

.statusCollapse{
  z-index: 0;
  position: sticky;
  top: 0px;
}

.searchContainer{
  padding: 10px 12px;
}

.actions {
  position: static;
}
