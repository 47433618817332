.overbooked {
  background: var(--overbooking-hint-color, #fe8f35);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  color: #fff;
}

.timeLabel, .guest {
  text-transform: uppercase;
}


.clientInfo,
.timeTableInfo,
.timeWordInfo {
  margin-top: 4px;
  color: var(--floorplanTable_text);
  font: 700 45px var(--font-roboto);
  text-align: center;
}

.timeTableInfo {
  font: 700 65px var(--font-roboto);
}

.table {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.scaled {
  border: 2px dashed var(--floorplanTable_focus_stroke);
  border-radius: var(--tb-border-radius);
}

.tableNumberHovered {
  background: var(--floorplanNumber_focus_background);
  border: 1.8px solid var(--floorplanNumber_empty_stroke);
  color: var(--floorplanNumber_focus_text);
}

.vipStar {
  width: 50px;
  height: 50px;
}
