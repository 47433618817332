@import "styles/mixins";

.card {
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.layer1{
  background: var(--gl_background_1);
}

.layer2{
  background: var(--gl_background_2);
}

.layer3{
  background: var(--gl_background_3);
}


.header {
  display: flex;
  position: relative;
  color: var(--gl_text_inverse);
  @include tablet {
    flex-direction: column;
  }
}

.headerContent{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 12px;
  border-bottom: 1px solid var(--gl_dividerBorder_primary);
  box-sizing: border-box;
  position: relative;
}


.title{
    font: var(--main-block-top-section-font);
    white-space: nowrap;
    margin-right: 20px;
    position: relative;
    height: 100%;

    display: flex;
    align-items: center;

    font: var(--font-16-b);

    &:after{
      content: " ";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: var(--gl_dividerBorder_accent);
      left: 0;
    }
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subHeader{
  display: flex;
  gap: 10px;
  @include tablet {
    padding: 12px;
    flex-direction: column;
  }
}

.tabs{
  --gl_background_3: transparent;
  --gl_dividerBorder_primary_2: var(--gl_dividerBorder_primary);
  height: 50px;;
}

.close{
  height: 16px;
  width: 16px;
  position: relative;
  > svg {
    position: absolute;
   }
}

.content{
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  padding: 10px;
}

.noPadding{
  padding: 0;
}

.shadowed{
  box-shadow: var(--shadow-light);
}

.cross{
  cursor: pointer;
}