.tabTitle {
  margin-right: 32px;
  cursor: pointer;
  position: relative;
  color: var(--gl_text_constant_secondary_disabled2);
  display: flex;
  gap: 10px;
  align-items: center;

  &:not(.disabled):hover {
    color: var(--tabs_hover_content);
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: transparent;
    position: absolute;
    bottom: -15px;
    transition: 0.3s ease-in all;
  }
}

.active {
  color: var(--gl_text_inverse);
  transition: 0.2s ease-in all;
  font: var(--font-16-b);
  font-weight: 700;
  &:after {
    background-color: var(--tabs_active_content);
    width: 100%;
  }
}

.disabled {
  color: var(--tabs_disabled_content);
  cursor: not-allowed;
}
