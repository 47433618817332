.icon{
  --icon-color: var(--floorplanLegend_deposite_content);
  border-radius: 50%;
  background: var(--floorplanLegend_deposite_background);
  padding: 10px;
}

.lock{
  --icon-color: var(--floorplanLegend_lock_content);
  background: var(--floorplanLegend_lock_background);
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}
