.field{
  margin-top: 15px;
  h3{
    margin-bottom: 0;
    color: #525252;
    white-space: nowrap;
    font: var(--font-12-b);
  }
}

.required {
  &::after{
    content: "*";
    font: var(--font-12-b);
  }
}


.label{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.error{
  font: var(--font-12-b);
  color: var(--petite-orchid);
}
