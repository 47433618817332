@import "styles/mixins";

.sidebar {
  background: var(--sidebar_inactive_background);
  color: var(--gl_text_constant_primary2);

  display: grid;
  grid-template-rows: auto min-content;
  width: var(--aside-width);
  border-right: 1px solid var(--gl_dividerBorder_secondary);
  height: calc(var(--win-height) - var(--header-height));

  @media print {
    display: none;
  }
}

.item {
  height: var(--nav-cell-height);
  font: var(--aside-name-font);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.title {
  color: var(--gl_text_constant_primary2);
  text-align: center;
}

.iconBlock{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
}

.icon {
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;

  path, rect {
    fill: var(--gl_icon_constant_secondary);
  }
}

.active {
  background-color: var(--sidebar_active_background);

  .icon {
    path, rect {
      fill: var(--gl_text_constant_primary2);
    }
  }
}

.counter {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: var(--badge_constant_background);
  position: absolute;
  right: 25px;
  font: var(--font-13-b);
  color: #ffffff;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1366px) {
    right: 17px;
    font: var(--font-12-b);
    width: 20px;
    height: 20px;
  }
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.name {
  font: var(--font-10-r);
  color: var(--gl_text_constant_primary2);

  flex: none;
  text-align: center;
  padding: 0 5px;
  overflow: hidden;
  width: var(--aside-width);
  box-sizing: border-box;
}

.userImg {
  width: var(--user-pic-side);
  height: var(--user-pic-side);
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.logout {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid var(--gl_icon_constant_secondary);
  flex: none;
  svg{
    color: var(--gl_icon_constant_secondary)
  }
}

.version {
  position: absolute;
  right: 5px;
  bottom: 2px;
  color: var(--cadet-blue);
  font-size: 0.7em;
}

.time {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(max-height: 800px) {
    display: none;
  }
}

.timeFormat {
  font: var(--aside-time-font);
  color: var(--sidebar-color, var(--mine-shaft));
}

.date {
  font: var(--aside-date-font);
  color: var(--sidebar-color, var(--boulder));

  .capitalize {
    text-transform: capitalize;
  }
}

@include tablet {
  .sidebar {
    position: fixed;
    left: calc(var(--aside-width) * -1);
    z-index: 10;
  }
  .open {
      position: relative;
      left: 0;
  }
}

.modeSwitch{
  flex: none;
  height: 50px;
}
