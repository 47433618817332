@import "~rc-dropdown/assets/index.css";
@import "~rc-menu/assets/index.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@import "styles/mixins";
@import "styles/colors";
@import "styles/colorsDark";

html {
  @include colors;

  --animation-duration: 0.3s;
  --shadow-sign: 0 2px 6px rgba(0, 0, 0, 0.25);
  --shadow-lightest: 0 2px 10px rgba(0, 0, 0, 0.15);
  --shadow-empty: 0 2px 8px rgba(37, 56, 73, 0);
  --shadow-thick: 0 2px 3px rgba(81, 86, 95, 0.15);
  --shadow-light: 0 2px 3px rgba(180, 199, 236, 0.35);
  --shadow-medium: 0 2px 8px rgba(37, 56, 73, 0.35);
  --shadow-strong: 0 4px 10px rgba(67, 109, 148, 0.5);
  --shadow-accent: 0 2px 3px rgba(81, 86, 95, 0.15), 0 4px 10px rgba(67, 109, 148, 0.5);
  --Border_Lightest: 0px 4px 11px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.1);

  --font-10-r: 500 10px var(--font-roboto);
  --font-11-r: 500 11px var(--font-roboto);
  --font-12-r: 500 12px var(--font-roboto);
  --font-12-b: 700 12px var(--font-roboto);
  --font-13-b: 700 13px var(--font-roboto);
  --font-13-r: 500 13px var(--font-roboto);
  --font-14-r: 500 14px var(--font-roboto);
  --font-14-b: 700 14px var(--font-roboto);
  --font-15-b: 700 15px var(--font-roboto);
  --font-16-r: 500 16px var(--font-roboto);
  --font-16-b: 700 16px var(--font-roboto);
  --font-18-r: 500 18px var(--font-roboto);
  --font-18-b: 700 18px var(--font-roboto);
  --font-20-b: 700 20px var(--font-roboto);
  --font-22-b: 700 22px var(--font-roboto);
  --font-24-b: 700 24px var(--font-roboto);
  --font-26-r: 500 26px var(--font-roboto);
  --font-26-b: 700 26px var(--font-roboto);
  --font-30-r: 500 30px var(--font-roboto);
  --font-30-b: 700 30px var(--font-roboto);
  --font-34-r: 500 34px var(--font-roboto);
  --font-40-r: 500 40px var(--font-roboto);
  --font-40-b: 700 40px var(--font-roboto);
  --font-50-r: 500 50px var(--font-roboto);
  --font-50-b: 700 50px var(--font-roboto);
  --font-60-r: 500 60px var(--font-roboto);
  --font-size-tablet: 11px;

  --border-default: 1px solid var(--botticelli);
  --border-1-solid-botticelli: 1px solid var(--botticelli);
  --border-2-solid-botticelli: 2px solid var(--botticelli);
  --border-2-solid-orchid: 2px solid var(--petite-orchid);
  --border-2-solid-athens: 2px solid var(--athens-gray);
  --border-2-solid-blue: 2px solid var(--dodger-blue);
  --border-2-transparent: 2px solid rgba(0, 0, 0, 0);
  --border-2-solid-chateau-green: 2px solid var(--chateau-green);
  --border-2-solid-hokey-pokey: 2px solid var(--hokey-pokey);
  --border-2-solid-chestnut-rose: 2px solid var(--chestnut-rose);

  --border-control: var(--border-2-solid-botticelli);

  --font-roboto: Roboto, sans-serif;

  --height-a: 31px;
  --input-padding: 0 12px;
  --input-font: var(--font-14-r);
  --action-button-font: var(--font-18-b);
  --pad: 20px;
  --scroll-width: 10px;

  --padding-side-button-tabblet: 4px;

  @media (max-width: 1366px) {
    --input-font: var(--font-12-r);
    --action-button-font: var(--font-16-b);
    --pad: 11px;
    --scroll-width: 4px;
    --input-padding: 0 4px;
  }

  @media (max-width: 950px) {
    --pad: 4px;
  }

  font-family: var(--font-roboto);
}

@include forDarkTheme {
  @include darkTheme;
}


@import './styles/themes';
