@import "styles/mixins";

#root main#settings {
  --z-index-multi: 0;
  position: relative;


  .block-content {

    &.appointRoles {
      @media (max-width: 1366px) {
        .search-block {
          justify-content: space-between;
        }
      }
    }

    .cards,
    .sections {
      height: auto;
      overflow: auto;

      .booking-split-section {
        img {
          width: 24px;
          height: 24px;
        }
        display: flex;
        padding: 0px 10px;
        align-items: center;
        height: 65px;
        min-height: 65px;
        font: var(--font-20-b);
        color: #484f54;
        span {
          margin-left: 15px;
        }
        background-color: white;
        position: sticky;
        top: 0;
        z-index: 1;

        @media (max-width: 1366px) {
          font: var(--font-14-b);
          height: 50px;
          min-height: 50px;
        }
      }
    }

    .appointHostess,
    .appointRoles {
      > div.active {
        --detail-height: 326px;

        &.count- {
          &1,
          &0 {
            --detail-height: 72px;
          }
          &2 {
            --detail-height: 135px;
          }
          &3 {
            --detail-height: 200px;
          }
          &4 {
            --detail-height: 263px;
          }
        }
      }

      &.one-tab div.active {
        --detail-height: auto;
      }

      .rest-info {
        position: sticky;
        top: 0px;
        cursor: pointer;
        z-index: 1;
        align-items: center;
        height: 64px;
        padding: 0px 24px 0px 20px;
        background-color: var(--collapse_administrations_default_background);
        border: 1px solid var(--gl_dividerBorder_primary);
        box-shadow: var(--shadow-thick);


        > span {
          font: var(--font-20-b);
          color: var(--gl_text_inverse);
        }
        > div {
          display: flex;
          align-items: center;

          .clickable {
            font: var(--font-16-b);
          }

          figure {
            cursor: pointer;
            margin-left: 15px;
          }
        }
      }

      @media (max-width: 1366px) {
        .rest-info > span {
          font: var(--font-16-b);
        }
        .clickable {
          font: var(--font-14-b);
        }
      }

      .table.rows {
        height: var(--detail-height);
        width: calc(100% - 6px);
        > :first-child:not(.message) {
          margin-top: 8px;
        }

        .table-row {
          > div {
            display: flex;
          }
          .edit {
            @include icon-svg-color-size("edit", var(--dodger-blue), 24px);
          }
          img {
            margin-left: 10px;
          }
          .edit,
          img {
            cursor: pointer;
          }

          .details {
            display: flex;
            align-items: center;
            width: 80%;
            color: var(--gl_text_inverse);

            .name {
              width: 35%;
            }
            .phone,
            .rest {
              width: 20%;
              display: flex;
              align-items: center;
              margin-left: 20px;
              svg{
                color: var(--gl_icon_constant_primary);
                path{
                  fill: var(--gl_icon_constant_primary);
                }
              }
              span {
                margin-left: 10px;
              }
            }
            > :first-child.rest {
              margin-left: 0px;
              img {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }

    .appointRoles > div.active {
      --detail-height: auto;
      &.count-5 {
        --detail-height: 326x;
      }
    }
  }
  .select-mode{
    width: 200px;
  }

  .search-block {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    gap: 5px;
    height: 50px;
    .hostess{
      min-width: 200px;
    }

    .reset-datepicker{
        right: 5px;
    }

    @include tablet {
      margin-left: 0;
      margin-top: 15px;
    }

    &.appointHostess > label {
        flex-grow: 1;
    }

    &.appointHostess .input-search {
      min-width: 230px;
      max-width: 260px;
    }

    &:not(.changeBook) > :not(:first-child) {
      margin-left: 20px;
    }

    button.primary {
      height: var(--height-a);
    }

    .separ {
      margin: 0px 15px;
    }

    .datepicker.from {
      margin-left: 20px;
    }

    .from,
    .to {
      min-width: 120px;
    }

    .datepicker.to {
      margin-right: 20px;
    }
  }

  .card-booking {
    .table {
      .table-cell- {
        width: 39%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &hostess {
          width: 30%;
        }

        &dateTime {
          width: 16%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &action {
          width: 20%;
        }

        &param {
          width: 34%;
        }
      }
    }
  }

  .empty-list {
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font: var(--font-24-b);
    color: var(--cadet-blue);
  }
}
