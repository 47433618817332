@use "sass:math";
$horizontalBasePadding: 9px;
$addonSize: 24px;

.inputContainer{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--gl_text_inverse);
  position: relative;
}

.input{
  border: 1px solid var(--gl_dividerBorder_primary);
  border-radius: 3px;
  background: var(--select_default_background_1);
  color: inherit;
  box-sizing: border-box;

  width: 100%;


  padding: 8px $horizontalBasePadding 7px;

  &:active, &:focus {
    border-color: var(--gl_dividerBorder_accent);
  }

  &:disabled{
    background: var(--select_disabled_background);
    border-color: var(--gl_dividerBorder_primary);
    color: var(--gl_text_secondary_disabled);
    cursor: not-allowed;
    -webkit-text-fill-color: var(--gl_text_secondary_disabled); /* Override iOS / Android font color change */
    -webkit-opacity:1; /* Override iOS opacity change affecting text & background color */
  }

  &::placeholder{
    color: var(--gl_text_secondary_disabled);
  }
}

.addon{
  --icon-color: var(--addon-color, var(--gl_icon_constant_secondary));
  color: var(--icon-color);
  position: absolute;
  width: $addonSize;
  height: $addonSize;
  top: 50%;
  transform: translate(0, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.withPrefix{
  padding-left: $horizontalBasePadding + $addonSize;
}

.withSuffix {
  padding-right: $horizontalBasePadding + $addonSize;
}

.prefix {
  left: math.div($horizontalBasePadding, 2);
}

.suffix{
  right: math.div($horizontalBasePadding, 2);
}

.invalid {
  border-color: var(--gl_icon_constant_danger);
}
