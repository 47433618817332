.block{
  padding: 24px 12px;
}



.badge{
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-column-gap: 16px;
  align-items: center;
}

.shortBlock{
  padding: 12px;
  grid-column-gap: 5px;
}

.info{
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  align-items: center;
  column-gap: 5px;
}

.photoWrapper{
  position: relative;
}

.photo {
  //display: var(--show-avatar-badge);
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.editPhoto{
  position: absolute;
  top: 25px;
  left: 20px;
  cursor: pointer;
}

.salutation{
  color: var(--gl_text_inverse);
  font: var(--font-12-b);
}

.name{
  color: var(--gl_text_inverse);
  font: var(--font-16-b);
  grid-column: span 2;
}

.phone{
  font: var(--font-14-r);
  color: var(--gl_text_inverse);
  font-weight: 400;
  grid-column: span 2;
}

.tagCount{
  font: var(--font-12-r);
  color: var(--gl_text_constant_primary2);
  background-color: var(--tags_constant_background_4);
  margin-left: 5px;
  margin-bottom: 10px !important;
}

.tags{
  margin-bottom: -10px;
  display: flex;
  & div{
    margin-bottom: 5px;
  }
}

.description{
  color: var(--gl_text_secondary_2);
  font: var(--font-12-r);
  grid-column: span 2;
}

.control{
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 12px;
}

.button{
  font-size: 14px;
}

.editButton{
  padding: 0 5px;
}
