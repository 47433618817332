@import "styles/mixins";
@import "styles/colors";

.custom-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 30px;
    border-radius: 30px;
  }
}

@keyframes empty-animation {
  from {
    background-color: unset;
  }
  to {
    background-color: unset;
  }
}

span[class$="indicatorSeparator"] {
  display: none;
}

.req-rejected {
  input {
    border: 2px solid red;
  }
}

textarea {
  padding-top: 8px;
  height: unset;
}

.input-search {
  position: relative;
  margin: 5px;

  @media (min-width: 950px) {
    margin: initial;
  }

  img {
    position: absolute;
    width: 24px;
    height: 24px;
    top: calc(50% - 11px);
    left: 10px;
  }

  input {
    padding-left: 40px;
    border: var(--border-1-solid-botticelli);
  }
}

.input-right {
  position: relative;
  display: flex;
  align-items: center;
  input {
    padding-right: 10px;
  }
  img,
  .suffix {
    position: absolute;
    right: 13px;
  }
  img {
    width: 24px;
    height: 24px;
  }
  .suffix {
    font: var(--font-16-r);
    color: var(--silver-chalice);
  }
}

div[class$="placeholder"] {
  color: var(--silver-chalice);
  @media (max-width: 1366px) {
    font-size: 11px;
  }
}

.blocks {
  display: flex;
  justify-content: space-between;
}

.clickable p {
  border-bottom: 1px dashed var(--dodger-blue);
  color: var(--dodger-blue);
  width: fit-content;
  cursor: pointer;
}

.sections {
  display: flex;
  flex-direction: column;

  &.include-table {
    &.one-tab {
      --detail-height: auto;
    }
    > div.active {
      --detail-height: 361px;

      &.count- {
        &1,
        &0 {
          --detail-height: 109px;
        }
        &2 {
          --detail-height: 172px;
        }
        &3 {
          --detail-height: 235px;
        }
        &4 {
          --detail-height: 298px;
        }
      }
    }
    .table {
      --padding-table-block: 0px;
      height: var(--detail-height);
      transition: height var(--animation-duration);
      box-sizing: border-box;
      overflow: auto;

      h2 {
        font: var(--font-20-b);
      }

      .header-table {
        display: flex;
        padding: 0px 18px;
        margin: 15px 23px 15px 20px;
        justify-content: space-between;
        font: var(--font-14-b);
        color: #abb0be;
      }

      > .message {
        font: var(--font-20-b);
        color: var(--cadet-blue);
        height: var(--detail-height);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .rows,
      &.rows {
        height: calc(var(--detail-height) - 46px);
        overflow: auto;
        background-clip: content-box;
        padding-right: 6px;
        --header-spacing: 6px;

        .table-row {
          overflow: hidden;
          display: flex;
          align-items: center;
          border: 1px solid var(--gl_dividerBorder_primary);
          box-sizing: border-box;
          box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
          border-radius: 3px;
          height: 55px;
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          padding: 18px;
          margin: 0px 17px 8px 20px;
          background-color: var(--itemrow_administrations_background);
          color: var(--gl_text_inverse);


          > div {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;

            color: #343434;
          }
        }
      }
    }
  }

  > div {
    --detail-height: 0;
    --collapse-button-bgc: none;
    --collapse-button-color: var(--icon-color, var(--heather));
    --collapse-button-rot: rotate(270deg);
    --cards-block-margin: 0;
    --card-ends-marging: var(--header-spacing);

    &.active {
      --collapse-button-bgc: var(--periwinkle-gray);
      --collapse-button-color: var(--dodger-blue);
      --collapse-button-rot: rotate(90deg);
    }

    .clickable p {
      border-bottom: 1px dashed var(--dodger-blue);
      width: fit-content;
      cursor: pointer;
    }

    > div:first-child:not(.booking-split-section) {
      margin: 0;
      width: auto;
      border: none;
      box-shadow: none;
      background: #f1f5fe;
      border: 1px solid #dde3ef;
      box-sizing: border-box;
      box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
      border-radius: 3px;

      .collapse {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transform: var(--collapse-button-rot);
        transition: all var(--animation-duration);
        background-color: var(--collapse-button-bgc);
        --ico-color: var(--gl_icon_inverse);
        grid-area: ref;
        justify-self: flex-end;
      }
    }
  }
}

*::-webkit-scrollbar {
  width: var(--scroll-width);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--alto);
}

/* SLIDER */
.switch {
  --height: 20px;
  position: relative;
  display: inline-block;
  width: 40px;
  height: var(--height);

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: var(--dodger-blue);
    }

    &:focus + .slider {
      box-shadow: 0 0 1px var(--dodger-blue);
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dodger-blue);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    /* Rounded sliders */
    &.round {
      border-radius: var(--height);

      &:before {
        border-radius: 50%;
      }
    }
  }
}
/* SLIDER  END*/
