@import "styles/mixins";

@mixin icon {
  width: 26px;
  height: 26px;
}

.legendContainer {
  width: 290px;
  position: absolute;

  top: 70px;
  left: calc(100vw - 290px - 71px);
  background-color: var(--legendModal_background);

  .content {
    display: flex;
    flex-direction: column;
    padding: 25px;

    color: var(--gl_text_inverse);
    > div {
      display: flex;
      padding-bottom: 18px;
      position: relative;
      align-items: center;

      img,
      svg {
        margin-right: 15px;
      }
      span {
        color: #525252;
      }

      .img {
        margin-right: 15px;
      }

      &:last-of-type {
        padding: 0;
      }
    }
  }
}

.legend {
  display: grid;
  grid-template-columns: 26px 1fr;
  gap: 15px;
  align-items: center;
  color: var(--gl_text_inverse);
}

.not-conf {
  @include icon;
  position: relative;
  img:nth-child(2) {
    position: absolute;
    left: 5px;
    top: 10px;
  }
}

.table{
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;


  &.free{
    background-color: var(--floorplanTable_empty_background);
    border: 2px solid var(--floorplanTable_focus_stroke);
    box-shadow: none;
  }

  &.confirmed{
    background-color: var(--floorplanTable_confirmed_background);
  }

  &.inHall{
    background-color: var(--floorplanTable_arrived_background);
  }

  &.late{
    background-color: var(--floorplanTable_late_background);
  }

  &.outstayed{
    background-color: var(--floorplanTable_outstayed_background);
  }

  &.number{
    background-color: var(--floorplanNumber_default_background);
    border: 2px solid var(--floorplanNumber_arrived_stroke);
    color: var(--gl_text_inverse);
    font: var(--font-12-r);
  }


  &.deposit{
    background-color: var(--floorplanLegend_lock_background);
    box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.15);
    &> svg path{
      fill: var(--floorplanLegend_lock_content);
    }
  }

}

.booked {
  @include icon;
  @include icon-svg-color("hall_help_status", #aeaeae);
}
.hall {
  @include icon;
  @include icon-svg-color("hall_help_status", #429867);
}
.not-come {
  @include icon;
  @include icon-svg-color("hall_help_status", #d8bc2b);
}
.expired {
  @include icon;
  @include icon-svg-color("hall_help_status", #cc5962);
}
