.modal {
  & > div:nth-child(1) {
    display: none;
  }
}

.icon {
  color: var(--gl_icon_inverse);
  width: 21px;
  height: 21px;
}

.content {
  display: grid;
  grid-template-columns: 21px 1fr;
  column-gap: 18px;
  padding: 16px;
  margin: 0;
}

.infoBlock {
  color: var(--gl_text_inverse);
  display: grid;
  row-gap: 5px;

  & > h5 {
    font: var(--font-16-b);
  }

  & > span {
    font: var(--font-14-r);
  }
}

.buttonBlock {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  margin-top: 10px;
  justify-content: end;
}

.secondary{
  background-color: var(--columnButton_secondary_background);
}
