@import "styles/mixins";


.phone{
  flex: 1;
  input:disabled {
    color: #ababab;
  }
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: flex-end;
}

.clear {
  display: var(--book-right-arr);
  position: absolute;
  right: 5px;
  top: calc(50% - 15px);
  margin-top: 5px;
}

.guestButton {
  display: flex;
  width: 34px;
  height: 34px;
  background-color: var(--athens-gray);
  padding: 6px;
  box-shadow: 0 0 10px #9ea3ae;
  border-radius: 100%;
  background: #fff;
  flex: none;
  align-self: flex-end;
}

.guestIcon {
  background: transparent;
  color: var(--dodger-blue);
}



@include forDarkTheme{
  .guestButton{
    background-color: var(--06dp);
    box-shadow: none;
  }
}
