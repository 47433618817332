@import "styles/mixins";
.container{
  background-color: var(--gl_background_1);
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  box-sizing: border-box;

  height: 100%;

  overflow: auto;

  grid-column: 1 / span 2;

  /* shadow-light */
  box-shadow: 0px 2px 3px rgba(180, 199, 236, 0.35);
  border-radius: 3px;
}


.paging{
  margin-top: 10px;
}

.modal{
  margin: 0;
  background-color: var(--gl_background_1);
  min-width: 1050px;
  @include tablet{
    min-width: auto;
  }
}

.scheme, .guests {
  min-width: 70vw;
  display: grid;
  min-height: 0;
  grid-template-rows: 1fr;
}


