.bookingHistory {
  overflow-y: scroll;
  background-color: var(--gl_background_1);
  height: 100%;
}

.bookingWrapper {
  margin-bottom: 5px;
  padding: 10px;
}

.logContainer {
  display: grid;
  background-color: var(--gl_background_2);
  margin-top: -5px;
  margin-bottom: 5px;
  padding-right: 16px;
  padding-left: 16px;
}

.logContainerHeader {
  display: grid;
  grid-template-columns: 140px 1fr 1fr 190px;
  font: var(--font-14-b);


  &__item {
    display: grid;
    padding: 18px 0;
    color: var(--gl_text_constant_secondary_disabled2);
    align-content: center;
  }
}

.logContainerBody {

  & > div {
    display: grid;
    grid-template-columns: 140px 1fr 1fr 190px;
    grid-gap: 5px;
  }

  &__item {
    color: var(--gl_text_inverse);
    padding: 18px 0;
    display: grid;
    align-content: center;
    font: var(--font-16-r);
    font-weight: 400;
    &--active{
      width: fit-content;
      color: var(--textButton_default_text);
      border-bottom: 1px dashed var(--textButton_default_text);
      cursor: pointer;
    }
  }
}


.bookingHistoryBookBody,
.bookingHistoryStatusBody,
.bookingHistoryMovingBody,
.bookingHistoryChangesBody{
  width: 680px;
  max-height: 524px;
  overflow-y: scroll;
  & > div:nth-child(2n){
    background-color: var(--table_gray_background);
  }
}

.section_header{
  color: var(--gl_text_constant_secondary_disabled2);
  text-transform: uppercase;
  font: var(--font-14-b) !important; // such css structure, sorry
}

.bookingHistoryBookSection,
.bookingHistoryStatusSection,
.bookingHistoryMovingSection,
.bookingHistoryChangesSection,
.bookingHistorySectionHeader {
  display: grid;
  grid-template-columns: 204px 1fr 1fr;
  grid-gap: 10px;
  padding-left: 20px;
  font-weight: 400;
  background: var(--gl_background_3);

  &__title{
    color: var(--gl_text_secondary_disabled);

    & > svg{
      margin-right: 3px;
      color: var(--gl_icon_primary_1);
    }
  }
}

.bookingHistoryValue {
  color: var(--gl_text_inverse);
  font: var(--font-16-r);

  &.bookingHistoryTitle {
    color: var(--gl_text_secondary_disabled);
  }
}

.bookingHistorySectionHeader {
  .bookingHistoryValue, .bookingHistoryTitle {
    font: var(--font-14-r);
    color: var(--gl_text_constant_secondary_disabled2);
  }
}

.bookingHistoryValue, .bookingHistoryTitle, .bookingHistorySectionHeader {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: 10px;
}

.bookingHistoryValue, .bookingHistoryTitle {
  padding-top: 18px;
  padding-bottom: 18px;
}

.bookingHistoryTitle {
  border-right: 1px solid var(--gl_dividerBorder_primary_2);
  display: grid;
  align-items: center;
}

.bookingHistoryStatusSection,
.bookingHistoryMovingSection{
  grid-template-columns: 204px 1fr;
}

.bookingHistoryChangesSection{
  grid-template-columns: 204px 1fr 1fr;
  &>div{
    &:first-child{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    &:nth-child(2){
      display: flex;
      align-items: center;
    }
    &:nth-child(3){
      display: flex;
      flex-direction: column;
      justify-content: center;

    }
  }

  .new{
    font: var(--font-16-r);
    font-weight: 400;
    color: var(--gl_text_inverse);
  }

  .old{
    color: var(--gl_text_secondary_disabled);
    font: var(--font-14-r);
    font-weight: 400;
    text-decoration: line-through;
  }
}

.historyModal{
  padding: 0;
  margin: 0;
  min-height: 575px;
  min-width: 680px;
}
