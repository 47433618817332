.form {
  margin: 0px var(--pad-left);
}


.actions{
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}


.cancel{
  border-bottom: 1px dashed var(--dodger-blue);
  color: var(--dodger-blue);
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  height: auto;
}
