foreignObject {
  overflow: visible;
  position: relative;
  margin: 3px;

  cursor: pointer;

  div.table-box div.pics {
    svg,
    span.count {
      cursor: pointer;
    }
  }

  &.disabled {
    caret-color: blue;
    cursor: not-allowed;

    div.table-box div.pics {
      svg,
      span.count {
        caret-color: blue;
        cursor: not-allowed;
      }
    }
  }

  --tb-background: var(--floorplanTable_empty_background);
  --tb-border: solid 2px var(--dove-gray);
  --tb-border-radius: 5px;
  --tb-w: calc(100%);
  --tb-h: calc(100%);

  --pb-strip: var(--mercury);
  --pb-background: var(--dodger-blue);

  --count-border: var(--floorplanNumber_empty_stroke);
  --count-color: var(--floorplanNumber_default_text);
  --count-pad: 1px;

  --ico-color: var(--floorplanTable_text);
  --time-info-color: var(--tundora-dark);

  --table-number-shift: -37.5px;
  // FOR TESTS
  --table-id-shift: 40px;

  &.green {
    --tb-background: var(--floorplanTable_arrived_background);
    --tb-border: none;

    --pb-strip: white;
    --pb-background: var(--amazon);

    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_arrived_background);
    --count-color: var(--floorplanNumber_default_text);

    --ico-color: var(--floorplanTable_text);
    --time-info-color: var(--ico-color);
  }

  &.yellow {
    --tb-background: var(--floorplanTable_late_background);
    --tb-border: none;

    --pb-strip: white;
    --pb-background: var(--hacienda);

    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_late_background);
    --count-color: var(--floorplanNumber_default_text);

    --ico-color: var(--floorplanTable_text);
    --time-info-color: var(--ico-color);
  }

  &.red {
    --tb-background: var(--floorplanTable_outstayed_background);
    --tb-border: none;

    --pb-strip: white;
    --pb-background: var(--copper-rust);

    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_outstayed_background);
    --count-color: var(--floorplanNumber_default_text);

    --ico-color: var(--floorplanTable_text);
    --time-info-color: var(--ico-color);
  }

  &.light_grey {
    --tb-background: var(--floorplanTable_confirmed_background);

    --pb-strip: white;
    --pb-background: dimgray;

    --ico-color: var(--floorplanTable_text);
    --time-info-color: var(--ico-color);

    --count-bg: var(--floorplanNumber_default_background);
    --count-border: var(--floorplanTable_confirmed_background);
    --count-color: var(--floorplanNumber_default_text);
  }

  &.round {
    --count-pad: 6px;
    --tb-w: 100%;
    --tb-h: 100%;
    --tb-border-radius: 100%;
    border-radius: 100%;
  }

  &.active {
    --tb-background: dimgray;
    --ico-color: white;
  }


  // FOR TESTS
  .table-id {
    z-index: 2;
    background: red;
    border: var(--count-border);
    color: var(--count-color);
    font: var(--font-40-b);
    box-shadow: var(--shadow-lightest);
    width: 75px;
    height: 75px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: var(--table-id-shift);
    top: var(--table-number-shift);
    position: absolute;
    box-sizing: border-box;
  }

  .star {
    z-index: 3;
    width: 65px;
  }

  .comment {
    z-index: 2;
    width: 65px;
  }
}

.table-box {
  width: var(--tb-w);
  height: var(--tb-h);
  background: var(--tb-background);
  box-shadow: var(--shadow-lightest);
  border: var(--tb-border);
  border-radius: var(--tb-border-radius);
  box-sizing: border-box;

  &.scaled {
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.round {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    --count-pad: 20px;
  }

  .pics {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    span {
      display: flex;
      align-items: center;
      color: var(--ico-color);
      user-select: none;
      -webkit-user-select: none;
    }

    img {
      margin-left: 5px;
    }

    .count {
      font: var(--font-60-r);
      user-select: none;
      -webkit-user-select: none;
      cursor: grab;
    }
  }

  .progress-bar {
    height: 10px;
    background: var(--pb-background);
    width: 80%;
    border-radius: 6px;

    .strip {
      height: 100%;
      background: var(--pb-strip);
      border-radius: 6px 6px 6px 6px;
    }
  }
}


.table-border {
  --border-color: transparent;

  box-sizing: border-box;
  border-width: 10px;
  border-style: solid;
  border-color: var(--border-color);
  background-color: var(--gl_background_floorplan);
  stroke: var(--border-color);

  //&:after{
  //  content: '';
  //  display: block;
  //  width: calc(100% + 10px);
  //  height: calc(100% + 10px);
  //  border: 10px solid var(--border-color);
  //  position: absolute;
  //  transform: translate(-15px, -15px);
  //  box-sizing: border-box;
  //  filter: blur(10px);
  //}

  &.round:after{
    border-radius: 50%;
  }

  &.green {
    --border-color: var(--floorplanTable_arrived_background);
  }

  &.yellow {
    --border-color: var(--floorplanTable_late_background);
  }

  &.red {
    --border-color: var(--floorplanTable_outstayed_background);
  }

  &.light_grey {
    --border-color: var(--floorplanTable_confirmed_background);
  }
}

.selectedTable .table-box{
  border: 10px solid var(--floorplanTable_focus_stroke);
}

.selectedForMove {
  box-shadow: 7px 9px 8px 5px var(--dodger-blue);
}

.moveSource,
.selectedTable {
  .table-box {
    border: 10px solid var(--tabs_inactive_content, rgba(49, 49, 49, 1));
  }
}
