.search{
  color: var(--gl_text_secondary_disabled);
  --button-height: 32px;

  display: flex;
  align-items: center;

  margin: var(--header-spacing);
  margin-bottom: 0px;
}

.searchInput{
  flex: 1 0;
  input, input[type=text] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.searchButton{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  height: 31px;

  font: var(--font-12-r);
}

.filterContainer{
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.statusFilter{
  flex: 1;
  width: 180px;
  margin: 0 4px;
}
