.calendarLoader, .grid {
  grid-column: span 2;
  background: var(--gl_background_2);
}

.tableResource {
  display: grid;
  row-gap: 5px;

  .title {
    font: var(--font-12-b);
    color: var(--gl_text_inverse);
  }

  .capacity {
    font: var(--font-12-r);
    color: var(--gl_text_secondary_disabled);
  }
}

.slotLabel {
  display: grid;
  row-gap: 5px;

  .capacity {
    font: var(--font-12-r);
    color: var(--gl_text_secondary_disabled);
  }
}

.slotTime {
  font: var(--font-12-b);
  color: var(--gl_text_inverse);
}

.booked {
  display: grid;
  grid-auto-flow: column;
  column-gap: 5px;

  .bookedCount {
    color: var(--gl_icon_primary_1);
    font: var(--font-12-b);
  }
}

.bookingItem {
  height: 40px;
  background: var(--gridBookingCard_default_background);
  border: none;
  padding: 0;
  border-radius: 3px;
}

.bookingItemInner {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content minmax(0, max-content) 1fr;
  align-items: center;
  column-gap: 8px;

  &.isOverbooking {
    border-bottom: 2px inset var(--floorplanLegend_overbooking_background);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.contextMenuIcon {
  width: 24px;
  display: grid;
  place-items: center;

  --icon-color: var(--gl_icon_constant_secondary);
}

.bookingStatus {
  border-radius: 0 3px 3px 0;
  height: 24px;
  width: 4px;
  justify-self: start;
}

.persons, .clientName {
  color: var(--gl_text_inverse);
  font: var(--font-12-b);
}

.clientName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.clientTags {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  margin-left: 4px;
}

.clientTag {
  width: 10px;
  height: 10px;
  border: 1px solid var(--16p);
  border-radius: 50%;
  margin-left: -4px;
}

.areaHeader {
  display: grid;
  row-gap: 4px;
  column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  cursor: pointer;

  .caption {
    font: var(--font-12-r);
    color: var(--gl_text_inverse);
    grid-row: span 2;
  }

  .arrow {
    color: var(--gl_icon_constant_disabled);

    &.active {
      color: var(--gl_text_inverse);
    }
  }

  .downArrow {
    transform: rotate(180deg);
  }
}

.menu {
  width: 216px;
  border: 1px solid var(--gl_dividerBorder_primary_2);
  padding: 10px 20px;
}

.cancelContent {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
}
