.list{
  position: relative;
  margin: var(--cards-block-margin);
  padding: 0 var(--header-spacing) 0 var(--header-spacing);
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  transition: height var(--animation-duration);
  box-sizing: border-box;
  animation: var(--card-content-animation);
  overflow: auto;

  & > :first-child {
    margin-top: var(--card-ends-marging);
  }
  & > :last-child {
    margin-bottom: var(--card-ends-marging);
  }
}

.card{
  flex: none
}

.blank {
  padding: 0 7px;
  font: var(--msg-font);
  color: var(--cadet-blue);
  text-align: center;
  align-self: center;
  height: 100%;
  display: flex;
  align-items: center;
}

.spinner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
