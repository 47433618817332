.tag {
  height: 18px;
  padding: 0 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gl_text_constant_primary2);
  background-color: var(--tags_constant_background_5);
  font-weight: bold;
  font-size: 12px;
  line-height: normal;
  font-style: normal;

  white-space: nowrap;
  &:not(:last-child){
    margin-right: 5px;
  }
}

.status{
  background-color: var(--status_constant_notconfirmed_background);
  color: var(--gl_text_content_primary);
}

.confirmed{
  background-color: var(--status_constant_confirmed_background);
}

.in_hall{
  background-color: var(--status_constant_arrived_background);
}

.wait_list{
  background-color: var(--status_constant_wait_list_background);
}

.not_come{
  background-color: var(--status_constant_noshow_background);
}

.canceled{
  background-color: var(--status_constant_cancelled_background);
}

.closed{
  background-color: var(--status_constant_completed_background);
}

