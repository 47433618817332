@import "styles/mixins";

[data-theme="wrf"]{
  --dodger-blue: #4ba837;
  --selago: #e7f3e4;
  --alto: #313131;
  --header-bg: #313131;
  --header-color: #fbfbfb;
  --sidebar-color: var(--header-color);
  --boulder: var(--header-color);
  --separator-color: #414141;
  --botticelli: #bbd2bc;
  --catskill-white: #f6eee6;
  --scheme-bg: var(--catskill-white);
  --icon-color: #3C3B3B;

  //change colors cards
  --newCardBook_confirmed_border: var(--newCardBook_booked_border) !important;
  --status_constant_confirmed_background: var(--status_constant_notconfirmed_background) !important;
  --newCardBook_confirmed_background:var(--newCardBook_booked_background) !important;
  --newCardBook_confirmed_timer: var(--newCardBook_booked_timer) !important;

  //change colors table
  --floorplanTable_confirmed_background: var(--status_constant_notconfirmed_background) !important;

  &[data-mode="dark"]{
    --floorplanTable_empty_background: #EFEFEF;
    --floorplanNumber_empty_stroke: #EFEFEF;
  }
}

[data-theme="lucky"]{
  --botticelli: #DCDDE1;
  --selago: #F2F4F8;
  --periwinkle-gray: #DBDFE7;
  --tropical-blue: #DCDDE1;
  --cadet-blue: #B7BAC1;
  --abbey: #4E5153;
  --dodger-blue: #2B2E31;
  --font-roboto: Helvetica, sans-serif;
}

[data-theme="dubai"] {
  --dodger-blue: #4ba837;
  --selago: #e7f3e4;
  --alto: #313131;
  --header-bg: #313131;
  --header-color: #fbfbfb;
  --sidebar-color: var(--header-color);
  --boulder: var(--header-color);
  --separator-color: #414141;
  --botticelli: #bbd2bc;
  --catskill-white: #f6eee6;
  --scheme-bg: var(--catskill-white);
  --icon-color: #3C3B3B;
}
