@import "styles/mixins";

.isBookingListShown{
  grid-template-columns: 2fr 3fr;
}

.hallScheme{
  position: relative;
  grid-column: span 2;
  min-height: 0;
  display: grid;
}

.editBooking{
  @include tablet {
   grid-column: 1/ span 2;
  }
}


.isEditOpen {
  .bookingsList {
    @include tablet {
      display: none;
    }
  }
}
