.checkbox{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: var(--gl_text_inverse);

  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  grid-column-gap: 8px;
  user-select: none;

  position: relative;

  input {
    display: none;
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  input:checked ~ .mark {
    background-color: var(--сheckbox_active_background);
    border: none;
  }

  input:checked ~ .mark:after {
    display: block;
  }

  .mark {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    background-color: var(--сheckbox_default_background);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid var(--gl_dividerBorder_primary);
    cursor: pointer;

    &:after {
      content: "";
      display: none;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 1.5px 1.5px 0;
      margin-top: -2px;
      margin-left: 1px;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }
}

.disabled {
  cursor: default;
  color: var(--silver-chalice);


  .mark {
      background-color: var(--сheckbox_disabled_background);
      border-color: var(--gl_dividerBorder_primary);
      &:after {
        border: solid var(--dodger-blue);
        border-width: 0 1.5px 1.5px 0;
      }
    }
}
