@import "styles/mixins";

$headerHeight: 60px;

.header{
  background: var(--header_background);
  height: $headerHeight;
  width: 100%;
  display: grid;
  grid-template-columns: var(--aside-width) 1fr;
  color: #fff;
  align-items: center;
  --columnBar-gap: 20px;
  gap: var(--columnBar-gap);

  box-sizing: border-box;
  padding-right: 12px;
}

.headerContent {
  grid-template-columns: 1fr auto 1.2fr;
  height: 100%;

  & > *:before {
    content: '';
    display: block;
    border-left: 1px solid var(--gl_dividerBorder_secondary);
    height: $headerHeight;
    position: absolute;
    left: calc(-1 * var(--columnBar-gap) / 2);
  }

  & > :first-child:before{
    display: none;
  }
}

.section {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
}

.logo, .menuControl {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo{
  @include tablet{
    display: none;
  }
}

.menuControl{
  display: none;
  @include tablet{
    display: flex;
  }
}
