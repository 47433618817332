.boldText{
  font: var(--font-12-r);
}

.greatBoldText{
  font: var(--font-12-b);
  &:disabled {
    color: #ababab;
  }
}


.radioButtons{
  display: grid;
  grid-auto-flow: column;
}

.guestInfo{
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: end;
}

.clientIcon{
  margin-left: 5px;
  path{
    fill: var(--gl_icon_constant_secondary);
  }
}
