.collapse {
  display: flex;
  flex-direction: column;
}

.header {
    background: var(--collapse_list_inactive_background_2);
    color: var(--gl_text_inverse);
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    padding: 16px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color, color;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease-in;

    &.active {
      background-color: var(--collapse_list_active_background_2);
      border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
    }
  }

  .chevron__block {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in;
  }

  .section {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    padding: 5px 0;
  }

  .icon {
    color: var(--gl_icon_primary_1);
  }

  .rotate {
    transform: rotate(180deg);
    background:var(--collapseButton_active_background);
    .icon{
      color: var(--gl_icon_accent);
    }
  }

  .collapse-body {
    overflow: hidden;
    transition: height 0.6s ease;
  }
