.bookingClientInfo{
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--gray);
}

.clientName{
  font: var(--font-18-b);
  color: var(--gl_text_inverse);
}

.gradeName {
  font: var(--font-12-r);
  color: var(--gl_text_secondary_disabled);
}
