.openSchemeContainer {
  display: flex;
  align-items: center;
  > div {
    flex: 1 0;
  }
}

.openScheme {
  margin-left: 10px;
  color: var(--dodger-blue);
}
