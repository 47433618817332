.TimeRangePicker {
  display: grid;
  grid-template-columns: 75px 10px 75px 10px;
  align-content: center;
  min-width: 230px;
  align-items: center;
  background-color: var(--select_default_background_1);
  border:1px solid var(--gl_dividerBorder_primary);
  box-sizing: border-box;
  border-radius: 3px;

  gap: 10px;
  padding: 10px;
  height: 33px;

  margin-left: 10px;
  position: relative;
  color: #BEC5D1;
}
