.menu{
  width: 216px;
}

.cancel{
  color: var(--petite-orchid);
  &>button:disabled{
    border: none;
    background: none;
  }
}

.cancelContent{
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
}
