.icon{
  cursor: pointer;
  color: var(--icon-color, #bec5d1);
  position: absolute;
  top: -10px;
  left: -10px;

  &.active {
      color:  var(--dodger-blue);
  }
}
