@mixin darkTheme() {
  --dodger-blue: #3F98DF;
  --header-bg: var(--03dp);
  --selago: var(--03dp);
  --page-bg: var(--01dp);
  --catskill-white: #434343;
  --scheme-bg: var(--page-bg);
  --count-bg: var(--page-bg);
  --selectBackground: var(--06dp);
  --selectColor: #fff;
  --mercury: var(--06dp);
  --periwinkle-gray: var(--06dp);
  --lateDark: #B26BF5;
  --silver-chaliceDark: #ababab;

  //Global colors

  //*Background*
  --gl_overlay_constant: #0000004D;
  --gl_background_1: #292929;
  --gl_background_2: #323232;
  --gl_background_3: #434343;
  --gl_background_gray_1: transparent;
  --gl_background_floorplan: #292929;
  --gl_background_accent: #363636;
  --gl_background_attention: #CA6B00;

  //*Text*
  --gl_text_inverse: #EDEFF2;
  --gl_text_secondary_2: #D9D9D9;
  --gl_text_secondary_disabled: #ABABAB;
  --gl_text_inverse2: #313131;
  --gl_text_accent: #3F98DF;
  --gl_status_arrived_text: #72E6F7;
  --gl_status_overstayed_text: #F7896F;
  --gl_status_late_text: #F7D694;
  --gl_status_confirmed_text: #60C689;
  --gl_status_notconfirmed_text: #ABABAB;
  --gl_status_wait_list_text: #CB94FF;
  --gl_status_noshow_text: #F75959;
  --gl_status_cancelled_text: #94CBF7;
  --gl_status_completed_text: #6FE583;
  --gl_status_starters_text: #CB94FF;
  --gl_status_main_text: #FA97FC;
  --gl_status_dessert_text: #FFAE52;
  --gl_status_checkdrop_text: #9EE189;
  --gl_status_paid_text: #60C689;
  --gl_status_bus_text: #F8CCFF;

  //*Text Constant*
  --gl_text_content_primary: #313131;
  --gl_text_constant_primary2: #FFFFFF;
  --gl_text_constant_secondary_disabled2: #ABABAB;
  --gl_text_constant_danger: #CC5962;

  //*Icon*
  --gl_icon_primary_1: #888888;
  --gl_icon_inverse: #E1E3E6;
  --gl_icon_accent: #3F98DF;

  //*Icon Constant*
  --gl_icon_constant_danger: #CC5962;
  --gl_icon_constant_primary: #ABABAB;
  --gl_icon_constant_primary2: #FFFFFF;
  --gl_icon_constant_secondary: #9B9B9B;
  --gl_icon_constant_disabled: #9B9B9BB2;

  //*Border/Divider*
  --gl_dividerBorder_primary: #434343;
  --gl_dividerBorder_primary_2: #545454;
  --gl_dividerBorder_secondary: #3A3A3A;
  --gl_dividerBorder_accent: #3F98DF;
  --gl_dividerBorder_hover: #375B77;



  //Control colors

  //*Header Dashboard*
  --header_background: #434343;

  //*Sidebar*
  --sidebar_inactive_background: #434343;
  --sidebar_active_background: #3F98DF;

  //*Header Buttons*
  --headerButton_primary_background: #3F98DF;
  --headerButton_secondary_background: #505050;

  //*Selects*
  --select_default_background_1: #363636;
  --select_default_background_2: #505050;
  --select_disabled_background: #505050;

  //*Selects menu*
  --selectMenu_default_background_1: #FFFFFF;
  --selectMenu_default_background_2: #505050;
  --selectMenu_hover_background: #375B77;
  --selectMenu_focus_background: #3F98DF;

  //*Checkbox*
  --сheckbox_default_background: transparent;
  --сheckbox_active_background: #3F98DF;
  --сheckbox_disabled_background: #505050;

  //*Checkbox Constant*
  --сheckbox_constant_active_content: #FFFFFF;

  //*Radio button*
  --radioButton_default_background_1: #363636;
  --radioButton_default_background_2: #505050;
  --radioButton_active_background: #3F98DF;
  --radioButton_disabled_background: #505050;

  //*Pickers*
  --pickerArrow_default_background_1: #363636;
  --pickerArrow_default_background_2: #505050;

  //*Collapse Button*
  --collapseButton_active_background: #474747;

  //*Legend*
  --legendButton_default_background: #434343;
  --legendButton_active_background: #3F98DF;
  --legendModal_background: #434343;

  //*Form button*
  --formButton_default_background: #363636;
  --formButton_active_background: #3F98DF;
  --formButton_disabled_background: #505050;

  //*Column Button*
  --columnButton_active_background: #3F98DF;
  --columnButton_secondary_background: #505050;

  //*Column Button Constant*
  --columnButton_constant_danger_background: #CC5962;
  --columnButton_constant_status_background: #F4A917;

  //*Scheme Button*
  --shemeButton_default_background: #3F98DF;

  //*Button small*
  --buttonSmall_default_background: #3F98DF;
  --buttonSmall_disabled_background: #505050;

  //*Button small Constant*
  --buttonSmall_constant_danger_background: #CC5962;

  //*Text button*
  --textButton_default_text: #3F98DF;

  //*Zoom button*
  --zoomButton_default_background: #434343;
  --zoomButton_default_content:#ABABAB;

  //*Date Picker*
  --dtpkr_background_1:#363636;
  --dtpkr_background_2:#505050;
  --dtpkrList_background:#505050;
  --dtpkrListItem_default_background:transparent;
  --dtpkrListItem_hover_background:#375B77;
  --dtpkrListItem_active_background:#3F98DF;
  --dtpkr_slot_hover_background:#375B77;
  --dtpkr_slot_active_background:#3F98DF;

  //*Title*
  --title_background_1:#292929;
  --title_background_2:#363636;
  --title_background_3:transparent;

  //*Badge constant*
  --badge_constant_background:#E08D8D;
  --badge_constant_content:#FFFFFF;

  //*Tags Constant*
  --tags_constant_background_1:#F4A917;
  --tags_constant_background_2:#8DB972;
  --tags_constant_background_3:#429867;
  --tags_constant_background_4:#589EEC;
  --tags_constant_background_5:#ABABAB;
  --tags_constant_background_6:#888888;
  --tags_constant_background_7:#CC5962;

  //*Status Constant*
  --status_constant_arrived_background: #72E6F7;
  --status_constant_overstayed_background:#F7896F;
  --status_constant_late_background:#F7D694;
  --status_constant_confirmed_background:#60C689;
  --status_constant_notconfirmed_background:#ABABAB;
  --status_constant_wait_list_background:#CB94FF;
  --status_constant_noshow_background:#F75959;
  --status_constant_cancelled_background:#94CBF7;
  --status_constant_completed_background:#6FE583;
  --status_constant_starters_background:#CB94FF;
  --status_constant_main_background:#FA97FC;
  --status_constant_dessert_background:#FFAE52;
  --status_constant_checkdrop_background:#9EE189;
  --status_constant_paid_background:#60C689;
  --status_constant_bus_background:#F8CCFF;

  //*Guests item list*
  --guestsIitemlist_default_background:#323232;
  --guestsIitemlist_hoverActive_background:#375B77;

  //*Collapse*
  --collapse_waiting_background:#363636;
  --collapse_administrations_default_background:#292929;
  --collapse_administrations_active_background:#292929;

  //*Item row Administrations*
  --itemrow_administrations_background:#323232;

  //*Bottom bar*
  --bottomBar_background_1:#323232;
  --bottomBar_background_2:#434343;
  --bottomBar_background_3: #505050;

  //*Time Slider*
  --timeSliderPrts_text:#ABABAB;
  --timeSliderPrts_slider_active_background:#3F98DF;
  --timeSliderPrts_slider_inactive_background: #505050;
  --timeSliderPrts_timeLablel_background: #3F98DF;

  //*Time Slider Constant*
  --timeSliderPrts_constant_knob_background:#FFFFFF;

  //*Dropdown Button*
  --dropdownButton_acccent_background:#3F98DF;
  --dropdownButton_secondary_background:#505050;

  //*Tooltip*
  --tooltip_background: #434343;

  //*Table*
  --table_default_background:transparent;
  --table_gray_background:#434343;
  --table_amaunt_background:#363636;

  //*New card booking*
  --newCardBook_background_1:transparent;
  --newCardBook_background_2:#505050;
  --newCardBook_other_border:#434343;
  --newCardBook_other_border_2:#545454;
  --newCardBook_arrived_background:#384F52;
  --newCardBook_overstayed_background: #5C413B;
  --newCardBook_late_background:#594F3E;
  --newCardBook_confirmed_background:#354D3D;
  --newCardBook_booked_background:#525252;
  --newCardBook_main_background:#593D59;
  --newCardBook_dessert_background:#523C26;
  --newCardBook_other_background:#525252;
  --newCardBook_arrived_border:#3D5659;
  --newCardBook_overstayed_border:#4D3631;
  --newCardBook_late_border:#4D4436;
  --newCardBook_confirmed_border:#364D3E;
  --newCardBook_booked_border:#434343;
  --newCardBook_main_border:#4D344D;
  --newCardBook_dessert_border:#4D3823;
  --newCardBook_arrived_timer:#72E6F7;
  --newCardBook_overstayed_timer: #F7896F;
  --newCardBook_late_timer:#F7D694;
  --newCardBook_confirmed_timer:#60C689;
  --newCardBook_booked_timer:#DCDCDC;
  --newCardBook_main_timer:#FA97FC;
  --newCardBook_dessert_timer:#FFAE52;
  --newCardBook_other_timer:#DCDCDC;

  //*Tabs*
  --tabs_inactive_content:#FCFDFF;
  --tabs_active_content:#3F98DF;
  --tabs_hover_content:#3F98DF;
  --tabs_disabled_content:#888888;

  //*Floorplan table*
  --floorplanTable_arrived_background:#62BFCC;
  --floorplanTable_dessert_background: #FFAE52;
  --floorplanTable_outstayed_background:#F7896F;
  --floorplanTable_main_background:#FA97FC;
  --floorplanTable_late_background:#F7D694;
  --floorplanTable_empty_background:#BDBDBD;
  --floorplanTable_confirmed_background:#60C689;
  --floorplanTable_focus_stroke: #FFFFFF;
  --floorplanTable_text:#292929;
  --floorplanTable_confirmed_future_background: #1E9951;
  --floorplanTable_notconfirmed_background: #BDBDBD;
  --floorplanTable_notconfirmed_future_background: #888888;

  //*Floorplan legend*
  --floorplanLegend_status_background:#F4A917;
  --floorplanLegend_status_content:#FFFFFF;
  --floorplanLegend_lock_background:#292929;
  --floorplanLegend_lock_content:#FFFFFF;
  --floorplanLegend_overbooking_background: #FE8F35;
  --floorplanLegend_overbooking_content:#FFFFFF;
  --floorplanLegend_deposite_background:#292929;
  --floorplanLegend_deposite_content:#FFFFFF;

  //*Floorplan number*
  --floorplanNumber_arrived_stroke:#62BFCC;
  --floorplanNumber_dessert_stroke:#F4A917;
  --floorplanNumber_outstayed_stroke:#F7896F;
  --floorplanNumber_main_stroke:#FA97FC;
  --floorplanNumber_late_stroke:#F7D694;
  --floorplanNumber_empty_stroke:#BDBDBD;
  --floorplanNumber_confirmed_stroke:#60C689;
  --floorplanNumber_default_background:#292929;
  --floorplanNumber_focus_background:#FFFFFF;
  --floorplanNumber_default_text:#FFFFFF;
  --floorplanNumber_focus_text:#292929;
  --floorplanNumber_confirmed_future_stroke: #1E9951;
  --floorplanNumber_notconfirmed_stroke: #BDBDBD;
  --floorplanNumber_notconfirmed_future_stroke: #888888;
  // Collapse
  --collapse_list_inactive_background_2: #363636;
  --collapse_list_active_background_2: #434343;

  //*Shifts*
  --shiftsSlot_actualy_default_bg: #375B77;
  --shiftsSlot_old_default_bg: #505050;

  //Grid
  --gridBookingCard_default_background: #505050;
}
