.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.prolongationBlock {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  gap: 10px;
}

.checkProlongation {
  width: 34px;
  height: 34px;
  background-color: var(--dodger-blue);
  border-radius: 5px;
}
