#booking {
  --top-input-width: 270px;
  @media (max-width: 950px) {
    --normalized-block-content-height: calc(var(--normalized-block-height) - 120px);
  }
  @media (max-width: 1366px) {
    --top-input-width: 150px;
  }
  grid-template-rows: min-content auto;

  .right-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .input-search {
      flex: 1;
    }
  }

  section {
    --badge-heght: 64px;
    --badge-font: var(--font-18-b);
    --collapse-button-bgc: none;
    --collapse-button-color: var(--icon-color, var(--heather));
    --collapse-button-rot: rotate(270deg);

    --card-content-overflow: hidden;
    --card-content-animation: none;

    --cards-block-margin: 0;
    --card-ends-marging: var(--header-spacing);
    min-height: 0;
    display: flex;
    flex-direction: column;

    &.active {
      flex: 1 0;
      --collapse-button-bgc: var(--periwinkle-gray);
      --collapse-button-color: var(--dodger-blue);
      --collapse-button-rot: rotate(90deg);
      --card-content-animation: empty-animation var(--animation-duration);
      margin-top: 10px;
    }

    &:not(.active) {
      .cards {
        display: none;
      }
    }

    &.active .cards.opened {
      --card-content-overflow: auto;
    }

    @media (max-width: 1366px) {
      --badge-font: var(--font-14-b);
      --badge-heght: 50px;

      &.active .cards {
        --cards-block-margin: 0;
      }
    }

    .badge {
      height: var(--badge-heght);
      background-color: var(--selago);
      align-items: center;
      padding: 0 20px;
      box-shadow: var(--shadow-medium);
      cursor: pointer;

      display: grid;
      grid-auto-flow: column;
      grid-template-columns: auto min-content min-content;
      grid-column-gap: 5px;
      color: var(--abbey);
      font: var(--badge-font);

      @media (min-width: 950px) {
        grid-column-gap: 22px;
      }

      > h3 {
        overflow-wrap: break-word;
      }

      .info {
        display: flex;
        align-items: center;
        > div {
          display: flex;
          align-items: center;
          margin-right: 5px;

          @media (min-width: 950px) {
            margin-right: 18px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }
        img,
        svg {
          margin-right: 10px;
        }
        span {
          font-weight: 500;
        }
        .img.booking {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          @include icon-svg-color("booking", var(--icon-color, #bec5d1));
        }
      }

      .collapse {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transform: var(--collapse-button-rot);
        transition: all var(--animation-duration);
        background-color: var(--collapse-button-bgc);

        --ico-color: var(--collapse-button-color);
      }
    }
  }

  .cards {
    margin: var(--cards-block-margin);
    padding: 0 var(--header-spacing) 0 var(--header-spacing);
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 15px;
    height: 100%;
    transition: height var(--animation-duration);
    box-sizing: border-box;
    overflow: var(--card-content-overflow);
    animation: var(--card-content-animation);

    & > :first-child {
      margin-top: var(--card-ends-marging);
    }
    & > :last-child {
      margin-bottom: var(--card-ends-marging);
    }
  }
}
