.moveStatus {
  position: absolute;
  padding: 10px;
  padding-bottom: 0;
  background: var(--selago);
  bottom: 95px;
  display: flex;
  gap: 10px;
  z-index: 1;
  width: 100%;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 20px;
  cursor: pointer;
  background-color: var(--dusty-gray) !important;
}
