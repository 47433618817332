.deposit{
  flex: 1;
  display: flex;
  gap: 10px;
}

.input{
  flex: 1 0;
  min-width: 80px;
}
