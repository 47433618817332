@import "styles/mixins";

#create-booking {
  display: block;
}

main {
  #call,
  #tables {
    display: none;
  }

  #call,
  #tables,
  #guests,
  #schedule-landing,
  #hall-scheme > div {
    border-radius: 3px;
    box-shadow: var(--shadow-light);
    background-color: white;
    .block-content {
      max-width: 100%;
      height: var(--normalized-block-content-height);
    }
  }

  #client-card.modal-active {
    position: relative;
  }

  #booking{
    overflow: hidden;
  }
  #booking {
    display: grid;
  }
  #guests {
    display: grid;
    overflow: hidden;
  }

  &#settings {
    grid-template-columns: 100%;
  }

  &#hall-scheme {
    width: calc(100vw - var(--aside-width));
    display: block;

    &.reg {
      --pad: 10px;
      --header-spacing: 10px;

      @media (max-width: 1366px) {
        grid-template-columns: 38% 62%;
      }

      @include tablet {
        grid-template-columns: 100%;
      }

      &.guests {
        grid-template-areas: "registration guests";
        @include tablet {
          grid-template-areas: "guests";
        }
        .hall-scheme-content {
          display: none;
        }
      }
    }

    &.table-booking {
      --pad: 10px;
      --header-spacing: 10px;
      padding: var(--pad);
      display: block;
      grid-template-areas: "tableBook scheme";
      grid-template-columns: 50% 50%;

      @include tablet {
        grid-template-areas: "tableBook";
        grid-template-columns: 100%;
      }
    }

    #guests {
      grid-area: guests;
    }

    #table-booking {
      grid-area: tableBook;
    }

    #guests {
      .block-content {
        height: calc(var(--normalized-block-content-height) + 20px);

        @media (max-width: 1366px) {
          height: var(--normalized-block-content-height);
        }
        .create {
          height: calc(var(--normalized-block-content-height) - var(--bottom-controls-height) + 20px);
          @media (max-width: 1366px) {
            height: calc(var(--normalized-block-content-height) - var(--bottom-controls-height) + 0px);
          }
        }
      }
    }
  }

  &.create-reg.scheme {
    grid-template-areas: "registration scheme";
  }

  &.create-reg {
    display: block;
  }
}


.container {
  display: flex;
  flex-direction: column;
  -webkit-print-color-adjust: exact;

  &.modal {
    --z-index-multi: 0;
  }
}

#content {
  display: flex;
  overflow: hidden;
  height: 100%;
  flex: 1;

  @media(max-height: 800px){
    --user-pic-side: 40px;
    --nav-cell-height: 75px;
  }
}
