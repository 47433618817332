@import "styles/mixins";

.bookingCard{
  display: flex;
  background: var(--newCardBook_background_1);
  border: 2px solid  var(--status-color-border, var(--newCardBook_booked_border));
  border-radius: 3px;
  --status-color: var(--status_constant_notconfirmed_background);

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.content{
  padding: 9.5px 12px;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: hidden;
}

.section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  --icon-color: var(--heather);
  min-height: 18px;
  //@media (max-width: 1024px) {
  //  flex-wrap: wrap;
  //}
  @include tablet{
    flex-wrap: wrap;
  }

}

.subSection{
  display: flex;
  align-items: center;
  gap: 10px;
  &:nth-child(1){
    //@media (max-width: 1024px) {
    //  align-items: flex-start;
    //  flex-direction: column;
    //  gap: 5px;
    //  margin-bottom: 5px;
    //}
    @include tablet{
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 5px;
    }
  }

}

.phone{
  color: var(--gl_text_inverse);
  font: var(--font-15-b);
}

.source_name {
  color: var(--gl_text_secondary_disabled);
  font: var(--font-12-r);
}



.interactive{
  cursor: pointer;
}

.active {
  --card-border: var(--border-2-solid-blue);
  --card-padding: calc(var(--card-pt) - 1px) calc(var(--card-pr) - 1px) calc(var(--card-pb) - 1px)
    calc(var(--card-pl) - 1px);
}

.canceled, .closed, .not_come, .wait_list {
  --status-color-border: var(--newCardBook_other_border);
  --visit-info-bg: var(--newCardBook_other_background);
  --timer-color: var(--newCardBook_other_timer);
}

.wait_list{
  --status-color: var(--status_constant_wait_list_background);
}

.not_come{
  --status-color: var(--status_constant_noshow_background);
}

.closed{
  --status-color: var(--status_constant_completed_background);
}

.canceled{
  --status-color: var(--status_constant_cancelled_background);
}

.confirmed{
  --status-color-border: var(--newCardBook_confirmed_border);
  --status-color: var(--status_constant_confirmed_background);
  --visit-info-bg: var(--newCardBook_confirmed_background);
  --timer-color: var(--newCardBook_confirmed_timer);
}

.in_hall{
  --status-color-border: var(--newCardBook_arrived_border);
  --status-color: var(--status_constant_arrived_background);
  --visit-info-bg: var(--newCardBook_arrived_background);
  --timer-color: var(--newCardBook_arrived_timer);
}

.late{
  --status-color-border: var(--newCardBook_late_border);
  --status-color: var(--status_constant_late_background);
  --visit-info-bg: var(--newCardBook_late_background);
  --timer-color: var(--newCardBook_late_timer);
}

.expired{
  --status-color-border: var(--newCardBook_overstayed_border);
  --status-color: var(--status_constant_overstayed_background);
  --visit-info-bg: var(--newCardBook_overstayed_background);
  --timer-color: var(--newCardBook_overstayed_timer);
}

.actions{
  width: 52px;
  flex: none;
  border-left: 1px solid var(--newCardBook_booked_border);
  --icon-color: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.action{
  width: 100%;
  flex: 1 1 100%;
  color: var(--gl_icon_constant_secondary);

  &:active, &:focus, &:hover {
    background: var(--gl_background_accent);
  }

  border-bottom: 1px solid var(--newCardBook_booked_border);
}

.userInfo{
  @extend .action;
  border: none;
}

.statusGroup, .compactStatusGroup{
  display: flex;
  align-items: center;
  gap: 10px;
  svg{
    color: var(--gray);
  }
}

.statusGroup{
  justify-content: space-between;
}
.noStatusBorder {
  border: none;
  border: 1px solid var(--gl_dividerBorder_primary);
}

//@include forDarkTheme {
//  .inHall{
//    --status-color: var(--floorplanTable_arrived_background);
//  }
//}
