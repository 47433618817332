.tableNumber {
  z-index: 4;
  background: var(--floorplanNumber_default_background);
  border: var(--count-border);
  color: var(--floorplanNumber_default_text);
  font: var(--font-50-b);
  box-shadow: var(--shadow-lightest);
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
}


