.tabs{
  display: flex;
  flex-direction: column;
}

.tabsList{
  background-color: var(--gl_background_3);
  border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabsContainer{
  display: flex;
  padding: 19px 12px 14px;
}
