.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  li{
    &:after{
      height: 4px;
    }
  }
}

.filters, .checkboxes{
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background-color: var(--gl_background_1);
}

.filters > * {
  flex: 0 1 186px;
}

.tableContainer{
  overflow: auto;
}

.table{
  border-spacing: 0 4px;
  border-collapse: separate;
  width: 100%;

  tbody::before{
    content: '';
    display: table-row;
    height: 10px;
  }
}

.header{
  position: sticky;
  top: 0;
  background-color: var(--gl_background_1);

  th {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    padding: 16px 12px;

    text-align: center;

    color: var(--gl_text_constant_secondary_disabled2);
    //background: #fff;

    &:first-child{
      width: 36px;
    }

    &:last-child{
      width: 62px;
    }
  }
}

.row{
  box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
  border-radius: 3px;

  td {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--gl_text_inverse);
    border-top: 1px solid var(--gl_dividerBorder_primary_2);
    border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
    background-color: var(--gl_background_2);

    &:first-child{
      border-left:  1px solid var(--gl_dividerBorder_primary_2);

    }
    &:last-child{
      background: var(--table_amaunt_background);
      border: 1px solid var(--gl_dividerBorder_primary_2);
    }
    &:first-child, &:last-child{
      padding: 15px 12px;
    }
  }
}

.footer{
  position: sticky;
  bottom: 0;
  background-color: var(--gl_background_accent);
  td{
    text-align: center;
    padding: 15px 12px;
    font: var(--font-14-b);
    color: var(--gl_text_inverse);
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 50px;
      height: 4px;
      background-color: var(--status-color);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.activeRow{
  box-shadow: 0 2px 3px rgb(81 86 95 / 15%), 0 4px 10px rgb(67 109 148 / 50%);;
}

.guests{
  color: var(--gray);
}

.spinner{
  position: absolute;
  height: auto;
  left: 50%;
  top: 50%;
}

.layout{
  grid-column: 1/3;
}
