@import "styles/mixins";

.menu{
  padding: 0;
  margin: 0;
  color: var(--tundora-dark);
}

.item{
  display: flex;
  align-items: center;
  min-height: 44px;
  box-sizing: border-box;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--mercury, #E2E2E2);
  }
}


.button{
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
  text-align: left;
  width: 100%;
  white-space: normal;

  .icon{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include forDarkTheme{
  .item{
    color: var(--wrf-white);
    &:not(:last-child) {
      border-bottom: 1px solid var(--08dp, #E2E2E2);
    }
  }
}
