.modalPortal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  &:empty {
    display: none;
  }
}

.modalOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
  padding: 0 12px;
  height: 50px;

  h3 {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    height: 100%;
    color: var(--gl_text_inverse);
    display: flex;
    align-items: center;

    position: relative;


    &:after{
      content: " ";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: var(--gl_dividerBorder_accent);
      left: 0;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  background: var(--gl_background_3);
  border-radius: 3px;
  max-height: 90vh;
  overflow: hidden;
}

.modalContent {
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  min-height: 0;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  overflow: auto;
}

.modalFooter {
  display: flex;
  align-items: center;
  gap: 30px;

  padding: 20px 12px;

  background-color: var(--selago);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.noPadding{
  padding: 0;
}
