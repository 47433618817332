@import "styles/mixins.scss";

.emptyContainer {
  height: 100%;
  --msg-font: var(--font-24-b);
  @media (max-width: 1366px) {
    --msg-font: var(--font-16-b);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;

  p {
    padding: 0px 7px;
    font: var(--msg-font);
    color: var(--cadet-blue);
    text-align: center;
    max-width: 600px;
  }
}

.icon {
  @include icon-svg-color-size("booking", var(--icon-color, #bec5d1), 220px);
  margin-bottom: 70px;
}


.closeDetails{
  cursor: pointer;
}
