.form{
  display: grid;
  grid: auto-flow min-content / repeat(2, calc((100% - 15px) / 2));
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(8, min-content);
  grid-gap: 10px;
  height: 100%;
  justify-content: start;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  align-content: start;
  align-items: end;
  padding: 10px;
}

.dateTime {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.fullRow{
  grid-column: span 2;
  :global{
    .content, .hours-mins{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
}

.twoCol {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: 10px;
}

.visitTime{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 10px;
  align-items: flex-end;
}

.location {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 10px;
  align-items: flex-end;
}

.printRow{
  display: flex;
  justify-content: flex-end;
  grid-column: 1 / span 2;
}
