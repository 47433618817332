@import "styles/mixins";

.trigger-button {
  width: 100%;
  cursor: pointer;
  align-items: center;
  outline: none;
  white-space: nowrap;
  justify-content: center;
  box-shadow: var(--shadow-light);
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  box-sizing: border-box;
  height: var(--height-a);
  border: var(--border-default);
  font: var(--font-12-r);

  &.active {
    // padding: var(--button-group-pad);
    background-color: var(--dodger-blue);
    color: white;
  }

}

@include forDarkTheme{
  .trigger-button{
    background-color: var(--12dp);
    color: var(--wrf-white);
    box-shadow: none;
    border: none;
    &.active {
      background-color: var(--dodger-blue);
      color: white;
    }
  }
}
