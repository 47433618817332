.container{
  width: 100%;
}

.invalid{
  border: 1px solid var(--gl_icon_constant_danger) !important;
}

.option{
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  grid-column-gap: 8px;
  cursor: pointer;
  user-select: none;
  input{
    display: none;
  }
}

.mark {
  flex: none;
  cursor: pointer;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  background-color: var(--сheckbox_default_background);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid var(--gl_dividerBorder_primary);
  margin-right: 10px;
  &:after {
    content: "";
    display: none;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    margin-top: -2px;
    margin-left: 1px;
    transform: rotate(45deg);
  }
}

.markChecked{
  background-color: var(--сheckbox_active_background);
  border: none;

  &:after {
    display: block;
  }
}

.optionText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--gl_text_inverse);
  font: var(--font-14-r);
  cursor: pointer;
}

.title{
  color: var(--gl_text_inverse);
  font: var(--font-12-r);
}

